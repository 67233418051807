import React from 'react'
import styled from 'styled-components'
import EmailIcon from '../../assets/mail.svg'
import { colors } from '../../styles/theme'

const EmailLink = ({ text, email, className }) => {
  return (
    <EmailContainer className={className}>
      <Icon src={EmailIcon}/>
      <Email href={`mailto:${email}`}>{text}</Email>
    </EmailContainer>
  )
}

export default EmailLink

const EmailContainer = styled.span`
  align-items: center;
  display: flex;
  padding-block-start: 30px;
`

const Email = styled.a`
  color: ${colors.primary};
  padding-top: 4px;
  font-size: 13px;
  margin-inline-start: 5px;
  text-decoration: none;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: ${colors.primary};
    transition: width .3s;
  }

  &:hover::after{
    width: 100%;
  }
`

const Icon = styled.img`
  margin-inline-end: 10px;
  width: 15px;
`
