import React from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import EmailLink from '../EmailLink'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import PhoneIcon from '../../assets/phone.svg';

const ContactGrid = ({ heading, list }) => {
  return (
    <ContactSection>
      { heading && <ContactHeading>{heading}</ContactHeading>}
      <ContactContainer>

        {list?.length &&
          list.map((item, index) => {
            const image = getImage(item.image)
            return (
              <ContactItem key={index}>
                <GatsbyImage image={image} alt='' width={100} height={100} imgStyle={{ borderRadius: '100%' }}/>
                <ContactContent>
                  <ContactItemHeading>{item.heading}</ContactItemHeading>
                  <ContactCopy>{item.sub_heading}</ContactCopy>
                  <ContactEmail email={item.email} text={item.email_label}/>
                  {item.phone &&
                    <PhoneContainer>
                      <Icon src={PhoneIcon}/>
                      <Contact href={`tel:${item.phone}`}>{item.phone}</Contact>
                    </PhoneContainer>
                  }
                </ContactContent>
              </ContactItem>
            )
          })
        }
      </ContactContainer>
    </ContactSection>
  )
}

export default ContactGrid

const ContactSection = styled.section`
  pading-inline: 20px;
  pading-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mq.tablet} {
    pading-inline: 80px;
    pading-bottom: 100px;
  }

`;

const ContactHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  margin-block-end: 40px;
  text-align: center;

  ${mq.tablet} {
    font-size: 40px;
  }
`

const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 40px;

  ${mq.tablet} {
    column-gap: 40px;
    flex-wrap: nowrap;
    justify-content: center;
    row-gap: 0;
  }
`;

const ContactItem = styled.div`
  align-items: center;
  column-gap: 20px;
  display: flex;
  width: 400px;
`

const ContactContent = styled.div`
  flex: 50%;
`

const ContactItemHeading = styled.h5`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 14px;
  margin-block-end: 5px;

  ${mq.tablet} {
    font-size: 16px;
  }
`

const ContactCopy = styled.p`
  font-size: 12px;
  margin-block-end: 10px;

  ${mq.tablet} {
    font-size: 13px;
  }
`

const ContactEmail = styled(EmailLink)`
  padding-block-start: 0;
`


const PhoneContainer = styled.span`
  align-items: center;
  display: flex;
  font-size: 13px;
`

const Contact = styled.a`
  color: ${colors.primary};
  text-decoration: none;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: ${colors.primary};
    transition: width .3s;
  }

  &:hover::after{
    width: 100%;
  }
`

const Icon = styled.img`
  margin-inline-end: 12px;
`
