import React from 'react'
import Layout from '../components/Layout/layout'
import HomepageMasthead from '../components/HomepageMasthead'
import FeaturedContentWithLinks from '../components/FeaturedContentRowWLinks'
import LeadResearcherRow from '../components/LeadResearcherRow'
import SCurveRow from '../components/SCurveRow'
import BenchmarkRow from '../components/BenchmarkRow'
import RelatedResources from '../components/RelatedResources'
import { graphql } from 'gatsby'
import styled from 'styled-components'

const IndexPage = ({ data, pageContext }) => {
  return (
    <Layout>
      <HomepageMasthead {...data.paragraphHomeMasthead} />
      <SCurveRow {...data.paragraphSCurveModule}/>
      <BenchmarkRow {...data.paragraphBenchmarkModule}/>
      <RelatedResourcesWrapper>
        <RelatedResources {...data.paragraphRelatedResources}{...data.allNodeResource}/>
      </RelatedResourcesWrapper>
      {pageContext.LeadResearcherArray.map ( (item, index) => {
        return (
          <LeadResearcherRow {...item} key={index} />)
      })}
      <FeaturedContentWithLinks {...data.paragraphFeaturedContentRowWithLinks}/>
    </Layout>
  )
}

export default IndexPage

export { Head } from '../components/_GatsbyPageHead'

const RelatedResourcesWrapper = styled.div`
  max-inline-size: 1440px;
  margin: auto;
`

export const query = graphql`
  query($NodeId: String!, $ResourceTopic: Int!, $RelatedResourcesId: String!){
    ...HomeMasthead
    ...BenchmarkRow
    ...RelatedResources
    ...DynamicResources
    ...LeadResearcher
    ...FeaturedContentLinks
    ...SCurveRow
  }
`
