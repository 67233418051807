import React from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import Link from '../Link'

const LinkRow = ({ tagline, heading, links, hasArrow }) => {
  return (
    <LinkRowWrapper>
      {tagline && <Tagline>{tagline}</Tagline>}
      <Heading>{heading}</Heading>
      <List>
        {links &&
          links.map((el, i) => (
            <ListElement key={i}>
              <Link href={el.uri.search("entity:node") != -1 ? (el.uri.search("/") != -1 ? `/questionnaire${el.uri.slice(el.uri.search("/"))}` : '/') : el.uri} text={el.title} hasArrow={hasArrow} target={el.newtab}/>
            </ListElement>
          ))
        }
      </List>
    </LinkRowWrapper>
  )
}

export default LinkRow

const LinkRowWrapper = styled.div`
  z-index: 1;
  border: 2px solid ${colors.white};
  padding: 25px 30px;

  ${mq.desktop} {
    padding: 40px 60px;
  }
`

const Tagline = styled.h6`
  color: ${colors.white};
  margin-block-end: 10px;
`

const Heading = styled.h2`
  color: ${colors.white};
  margin-block-end: 20px;
`

const List = styled.ul`
  li:last-child {
    margin-block-end: 0px
  }
`

const ListElement = styled.li`
  margin-block-end: 30px;
  transition: all 0.2s ease-in-out;

  ${mq.desktop} {
    margin-block-end: 20px;
  }
`
