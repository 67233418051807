import React, { useState, useRef, useEffect } from 'react'
import Button from '../Button'
import EmailLink from '../EmailLink'
import VideoModal from '../VideoModal'
import playIcon from '../../assets/play icon.svg'
import { mq, colors } from '../../styles/theme'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const LeadResearcherRow = props => {
  const [show, setShow] = useState(false)
  const playerRef = useRef(null)

  useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.showPreview();
    }
  }, [show]);

  const {
    field_label_email_1,
    field_label_email_2,
    field_email,
    field_phone_or_email,
    field_prgph_copy,
    field_prgph_heading_plain_text,
    field_prgph_sub_headi,
    field_type_of_field,
    relationships: { field_prgph_image, field_video_modal }
  } = props

  const image = field_prgph_image.relationships.field_media_image.localFile
  const LeadImage = getImage(image)
  //   var video = null;
  //   if(field_video_modal){
  //    video = {
  //     heading: field_video_modal?.field_link_row_heading || null,
  //     copy: field_video_modal?.field_prgph_copy || null,
  //     link: field_video_modal?.relationships.field_youtube_link?.field_media_oembed_video || null
  //   }
  // }


  return (
    <LeadResearcherSection>
      <LeadResearcherHeading>{field_prgph_heading_plain_text}</LeadResearcherHeading>
      <LeadResearcherContent>
        <GatsbyImage image={LeadImage} alt='' />
        <LeadResearcherDetail>
          <LeadResearcherCopy>
            <p>{field_prgph_copy}</p>
          </LeadResearcherCopy>
          {field_label_email_1 && <Button text={field_label_email_1} type='email' link={field_email} />}
          {field_prgph_sub_headi && <LeadResearcherSubHeading>{field_prgph_sub_headi}</LeadResearcherSubHeading>}
          {/* {field_label_email_2 && <EmailLink email={field_phone_or_email} text={field_label_email_2} />} */}
        </LeadResearcherDetail>
        {/* {video && <LeadResearcherVideoCard>
          <LeadResearcherVideoCardContent>
            {video.heading && <LeadResearcherVideoCardHeading>{video.heading}</LeadResearcherVideoCardHeading>}
            {video.copy && <LeadResearcherVideoCardCopy><p>{video.copy}</p></LeadResearcherVideoCardCopy>}
          </LeadResearcherVideoCardContent>
          {video && video.link && <LeadResearchVideoWrapper>
            <LeadResearchVideoThumbnail playing controls ref={playerRef} onClickPreview={() => setShow(true)} url={video.link} light={true} width='100%' height='auto' playIcon={<PlayOverlay/>}/>
            <LeadResearcherVideoIconWrapper onClick={() => setShow(true)} className={'play-button'}><LeadResearcherVideoIcon onClick={() => setShow(true)} src={playIcon}/></LeadResearcherVideoIconWrapper>
          </LeadResearchVideoWrapper>}
        </LeadResearcherVideoCard>} */}
      </LeadResearcherContent>
      {/* {video && video.link && <VideoModal onClose={() => setShow(false)} show={show} url={video.link} />} */}
    </LeadResearcherSection>
  )
}

export default LeadResearcherRow

const LeadResearcherSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-block: 100px;
  margin-inline: 20px;
  max-inline-size: 1000px;
  min-block-size: 300px;

  ${mq.desktop} {
    margin-inline: auto;
  }
`

const LeadResearcherHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  margin-block-end: 40px;
  text-align: center;

  ${mq.desktop} {
    font-size: 40px;
    line-height: 42px;
  }
`

const LeadResearcherDetail = styled.div`
  flex: 50%;
`

const LeadResearcherContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  position: relative;

  ${mq.desktop} {
    flex-wrap: nowrap;
  }
`

const LeadResearcherCopy = styled.div`
  margin-block-end: 30px;
`

const LeadResearcherSubHeading = styled.h5`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 14px;
  margin-block-start: 50px;

  ${mq.desktop} {
    font-size: 15px;
  }
`

const LeadResearcherVideoCard = styled.div`
  background-color: ${colors.white};
  border-block-start: 10px solid ${colors.primary};
  border-inline-start: none;
  display: flex;
  flex-direction: column;

  ${mq.desktop} {
    bottom: 0;
    border-block-start: none;
    border-inline-start: 10px solid ${colors.primary};
    flex-direction: row;
    min-block-size: 180px;
    max-block-size: 180px;
    position: absolute;
    right: 0;
    transform: translateY(50%);
    width: 730px;
  }
`

const LeadResearcherVideoCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: 40px;
  padding-inline: 30px;

  ${mq.desktop} {
    padding-block: 40px;
    padding-inline: 50px;
  }
`

const LeadResearcherVideoCardHeading = styled.h5`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 14px;

  ${mq.desktop} {
    font-size: 15px;
  }
`

const LeadResearcherVideoCardCopy = styled.div`
`

const LeadResearcherVideoIconWrapper = styled.div`
  bottom: 10px;
  position: absolute;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
`

const LeadResearcherVideoIcon = styled.img`
  width: 12px;
  position: absolute;
  top: 35%;
  left: 40%;
  transition: transform .3s ease;
`

const LeadResearchVideoThumbnail = styled(ReactPlayer)`
  overflow: hidden;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;

  ${mq.desktop}{
    position: initial;
  }

  .react-player__preview {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    position: relative;
    transition: transform .3s ease;

    &:hover{
      transform: scale(1.1);
    }
  }
`

const LeadResearchVideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;

  ${mq.desktop} {
    position: initial;
    padding-top: initial;
  }

  min-width: 320px;

  &:hover{
    ${LeadResearcherVideoIconWrapper} {
      img{
        transform: scale(1.2);
      }
    }

    ${LeadResearchVideoThumbnail} {
      .react-player__preview {
        transform: scale(1.1);
      }
    }
  }
`

const PlayOverlay = styled.div`
  width: 100%;
  height: 100%;
`
