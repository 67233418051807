import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import useOnScreen from '../../hooks/useOnScreen'
import { StaticImage } from 'gatsby-plugin-image'
import { mq } from '../../styles/theme'
import Chapter from './Chapter'

const BenchmarkRow = props => {
  const ref = useRef();
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [target, setTarget] = useState(0.7);
  const onScreen = useOnScreen(ref);
  let [y, setY] = useState(null)
  if (typeof window !== 'undefined'){
    [y, setY] = useState(window.scrollY);
  }

  const {
    field_prgph_copy, field_prgph_heading_plain_text, relationships
  } = props

  useEffect(() => {
    if (typeof window !== 'undefined'){
      window.addEventListener('scroll', animateImage);
      return () => window.removeEventListener('scroll', animateImage);
    }
  });

  const animateImage = e => {
    const window = e.currentTarget;
    if (y > window.scrollY) {
      if (onScreen) {
        setRotation(rotation + 0.5)
      }
    } else if (y < window.scrollY) {
      if (onScreen) {
        setRotation(rotation - 0.5)
      }
    }
    if (scale < target) {
      setScale(scale + 0.0015)
    } else {
      setScale(scale - 0.0015)
    }

    if (scale <= 0.7) {
      setTarget(1)
    } else if (scale >= 1) {
      setTarget(0.7)
    }
    setY(window.scrollY);
  }

  return (
    <BenchmarkSection>
      <BenchmarkContainer>
        <BenchmarkLeftCol>
          <BenchmarkImageContainer ref={ref}>
            <BenchmarkCopy>
              <BenchmarkHeading>{field_prgph_heading_plain_text}</BenchmarkHeading>
              <BenchmarkText>{field_prgph_copy}</BenchmarkText>
            </BenchmarkCopy>
            <StaticImage src='../../assets/orb_1.png' width={447} height={447} alt='' style={{ transform: `rotate(${rotation}deg) scale(${scale})` }}/>
          </BenchmarkImageContainer>
        </BenchmarkLeftCol>

        <BenchmarkRightCol>
          <BenchmarkCopyMobile>
            <BenchmarkHeading>{field_prgph_heading_plain_text}</BenchmarkHeading>
            <BenchmarkText>{field_prgph_copy}</BenchmarkText>
          </BenchmarkCopyMobile>
          <BenchmarkChapterWrapper>
            {relationships.field_questionnaire_reference.relationships.field_chapters.map((el, i) => (
              el.relationships && <Chapter key={i} copy={el.field_chapter_teaser} heading={el.field_prgph_heading_plain_text} chapterType={el.relationships?.field_theme.field_theme_key} rotation={rotation} />
            ))}
          </BenchmarkChapterWrapper>
        </BenchmarkRightCol>
      </BenchmarkContainer>
    </BenchmarkSection>
  )
}

export default BenchmarkRow

const BenchmarkSection = styled.section`
  margin-inline: 20px;

  ${mq.desktop} {
    margin-inline: 80px;
  }
`

const BenchmarkContainer = styled.div`
  display: flex;
`

const BenchmarkCopy = styled.div`
  display: none;
  align-items: center;
  flex-direction: column;

  ${mq.desktop} {
    display: flex;
    position: absolute;
  }
  z-index: 5;
`

const BenchmarkCopyMobile = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${mq.desktop} {
    display: none;
  }
  z-index: 5;
`

const BenchmarkText = styled.p`
  max-inline-size: 410px;
  margin-block-end: 50px;
  text-align: center;

  ${mq.desktop} {
    margin-block-end: 0;
  }
`

const BenchmarkLeftCol = styled.div`
  display: none;
  flex: 1;

  ${mq.desktop} {
    display: block;
  }
`

const BenchmarkHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  max-inline-size: 450px;
  font-size: 30px;
  text-align: center;
  line-height: 1.05;
  margin-block-end: 35px;

  ${mq.desktop} {
    font-size: 40px;
    margin-block-end: 12px;
  }
`

const BenchmarkImageContainer = styled.div`
  align-items: center;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  display: flex;
  justify-content: center;
  margin-block-start: 400px;
  position: sticky;
  top: calc(35% - 62px);
  width: 100%;
  z-index: 1;
`

const BenchmarkRightCol = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const BenchmarkChapterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  width: 100%;
`
