import React from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import OrbImage from '../OrbImage';

const BenchmarkChapter = ({ heading, copy, chapterType, rotation }) => {
  return (
    <ChapterContainer>
      <OrbImageWrapper  style={{ transform: `rotate(${rotation}deg)` }}>
        <OrbImage type={chapterType}/>
      </OrbImageWrapper>
      <ChapterCopyWrapper>
        <ChapterHeading>{heading}</ChapterHeading>
        <ChapterCopy>{copy}</ChapterCopy>
      </ChapterCopyWrapper>
    </ChapterContainer>
  )
}

export default BenchmarkChapter

const ChapterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;

  ${mq.desktop} {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-block-size: 800px;
    background-color: ${colors.gray};
  }
`

const OrbImageWrapper= styled.div`
  padding: 0px 0px 0px 0px;
  min-width: 100px;
  width: 100px;

  ${mq.desktop} {
    width: 200px;
    padding: 0px 0px 30px 0px;
  }
`

const ChapterCopyWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-inline-size: 275px;
  padding: 0px 0px 0px 30px;


  ${mq.desktop} {
    align-items: center;
    padding: 0;
  }
`

const ChapterHeading = styled.h5`
  font-family: "BuckeyeSans";
  font-weight: 700;
  margin-block-end: 5px;
`

const ChapterCopy = styled.p`
  text-align: left;

  ${mq.desktop} {
    text-align: center;
  }
`
