import React from 'react';
import styled from 'styled-components';
import Cta from '../Cta';
import LinkRow from '../LinkRow';
import { mq, colors } from '../../styles/theme';
import Wave from '../Wave';


const HomepageMasthead = props => {
  const {
    field_link_row_heading,
    field_link_row_links_tab,
    field_link_row_tag_line,
    field_prgph_copy,
    field_prgph_heading_plain_text
  } = props
  return (
    <Container>
      <Cta heading={field_prgph_heading_plain_text} copy={field_prgph_copy} />
      <Wave/>
      <LinkRow
        tagline={field_link_row_tag_line}
        heading={field_link_row_heading}
        links={field_link_row_links_tab}
        hasArrow
      />
    </Container>
  )
}


export default HomepageMasthead

const Container = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-block-size: 535px;
  padding: 60px 30px;
  background: ${colors.mainWaveColor};
  overflow: hidden;
  flex-direction: column;
  block-size: calc(100vh - 60px);

  ${mq.desktop} {
    flex-direction: row;
    flex-wrap: nowrap;
    min-block-size: 750px;
    block-size: calc(100vh - 100px);
  }
`;
