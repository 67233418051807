import React from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'

const Cta = ({ heading, copy }) => {
  return (
    <CtaWrapper>
      <Heading>{heading}</Heading>
      <Copy>{copy}</Copy>
    </CtaWrapper>
  )
}

export default Cta

const CtaWrapper = styled.div`
  z-index: 1;
  max-inline-size: 470px;
`

const Heading = styled.h1`
  color: ${colors.white};
  margin-block-end: 20px;
`
const Copy = styled.p`
  color: ${colors.white};
  font-size: 14px;
  margin-block-end: 40px;

  ${mq.desktop} {
    font-size: 16px;
  }
`
