import React, { useEffect } from 'react'
import ReactPlayer from 'react-player/youtube'
import styled from 'styled-components'
import closeIcon from '../../assets/close.svg'

// Use of video modal: <VideoModal onClose={() => setShow(false)} show={show} url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />

const VideoModal = ({ show, onClose, url }) => {

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEsc)
    return () => {
      document.body.removeEventListener('keydown', closeOnEsc)
    }
  })

  if (!show){
    return null
  }

  const closeOnEsc = e => {
    if ((e.charCode || e.keyCode) === 27){
      onClose()
    }
  }

  return (
    <ModalWrapper onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()} role='dialog' aria-modal='true'>
        <ModalBody>
          <VideoPlayer controls playing={true} muted={true} width='100%' height='100%' url={url} />
        </ModalBody>
      </ModalContent>
      <CloseButton onClick={onClose}><CloseIcon src={closeIcon}/></CloseButton>
    </ModalWrapper>
  )
}

export default VideoModal

const ModalWrapper = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
`

const ModalContent = styled.div`
  max-block-size: 512px;
  width: 840px;
`

const VideoPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

const CloseButton = styled.button`
  background: 0 0;
  border: 0;
  cursor: pointer;
  outline: 0;

  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-20px, 20px);
`

const CloseIcon = styled.img``

const ModalBody = styled.div`
  position: relative;
  padding-top: 56.25% 
`
