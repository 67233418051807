import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/theme';

const Wave = ({left, top}) => {

  return (
    <WaveContainer left={left} top={top}/>
  )
}

export default Wave

const WaveContainer = styled.div`

  position: relative;
  left: ${props => props.left ? props.left + '%' : '50%'};
  top: ${props => props.top ? props.top + 'px' : '0px'};

  &:after {
    content: "";
    position: absolute;
    min-width: 300vw;
    min-height: 300vw;
    background: ${colors.black};
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    bottom: 50%;
    opacity: .1;
    border-radius: 35%;
    animation-duration: 50s;
  }

  @keyframes rotate {
    0% {transform: translate(-50%, 0) rotateZ(0deg);}
    50% {transform: translate(-50%, -2%) rotateZ(180deg);}
    100% {transform: translate(-50%, 0%) rotateZ(360deg);}
  }
`;
